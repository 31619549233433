import { Col, Layout, Row } from "antd";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import Login from "./components/Auth/Login";
import CardImports from "./components/Cards/CardImports";
import CardsDashboard from "./components/Cards/CardsDashboard";
import Clients from "./components/Clients/routes/index";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Leads from "./components/Leads/Leads";
import LeftNavigation from "./components/LeftNavigation/LeftNavigation";
import Staff from "./components/Settings/UsersControls/StaffUser/Staff";
import Users from "./components/Users/routes/index";
import history from "./history";
import CardVendors from "./components/Cards/CardVendors/CardVendors";
import { useState } from "react";
import Dashboard from "./components/Home/Home";

export const HeaderFooterWrap = (props) => {
  return (
    <Layout className="flex-row" style={{
      minHeight: "100vh"
    }}>
      <LeftNavigation state={props.state} setState={props.setState} activeMenu={props?.state.activeMenu} setActiveMenu={(val) => {
        props.setState({
          ...props?.state,
          activeMenu: val
        })
      }} />
      <Layout className="site-layout main-content">
        <Layout.Content>
          <Header />
          {props.children}
        </Layout.Content>
      </Layout>
      {/* <Footer /> */}
      {/* <div className="grid main-area">
        <div className="g-header pt1">
          <Header />
        </div>
        <div className="g-nav">
          <LeftNavigation />
        </div>
        <div className="g-content">
          {props.children}
        </div>
        <div className="g-footer">
          <Footer />
        </div>
      </div> */}
    </Layout>
  );
};
const RedirectLogin = () => {
  return <Redirect to={"/admin/login"} />
}

function App() {
  const [state, setState] = useState({
    collapsed: false,
    selectedKey: "8",
    activeMenu: "dashboard"
  })
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <div>
            <Route exact path={"/"} component={RedirectLogin} />
            <Route path="/admin/login" component={Login} exact />
            <Route path="/admin/resetpassword/" component={Login} />
            <Route
              path="/admin/dashboard"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap state={state} setState={setState}>
                    <Dashboard path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/clients"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap state={state} setState={setState}>
                    <Clients path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/users"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap state={state} setState={setState}>
                    <Users path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/staff-users"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap state={state} setState={setState}>
                    <Staff path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/card-vendors"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap state={state} setState={setState}>
                    <CardVendors path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/leads"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap state={state} setState={setState}>
                    <Leads path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/cards/imports"
              exact
              render={({ match }) => {
                return (
                  <HeaderFooterWrap state={state} setState={setState}>
                    <CardImports path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/cards"
              exact
              render={({ match }) => {
                return (
                  <HeaderFooterWrap state={state} setState={setState}>
                    <CardsDashboard path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
          </div>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
