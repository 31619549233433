import { message } from "antd";
import axios from "axios";

export const APP_BASE_URL = "https://adminpayapi.purpleplumfi.com";

const endpoint = axios.create({
  baseURL: APP_BASE_URL,
});


endpoint.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

endpoint.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    response.headers["ngrok-skip-browser-warning"] = 765

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status === 401 && error?.response.data?.message === "jwt expired") {
      message.error(<span className="messageText">Session Expired.</span>)
      localStorage.clear()
      window.location.href = "/admin/login"
    };
    return Promise.reject(error);
  }
);

export default endpoint;
